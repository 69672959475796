<template>
  <div>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <b-form-select
      v-model="status"
      :options="status_options"
      class=""
      value-field="value"
      text-field="text"
      @change="changeStatus"
    ></b-form-select>
  </div>
</template>
<script>
import helpers from "../../helpers";
import ApiService from "@/core/services/api.service";

export default {
  name: "StatusSelect",
  props: {
    slotData: Object
  },
  data: function() {
    return {
      loader: false,
      status: "",
      status_options: [
        { text: "Cancelled", value: "Cancelled" },
        { text: "Completed", value: "Completed" },
        { text: "Pending", value: "Pending" }
      ]
    };
  },
  methods: {
    changeStatus() {
      let obj = {
        status: this.status
      };
      this.loader = true;
      ApiService.patch(
        this.$path + "/admin/reservation/status/" + this.slotData.uuid,
        obj
      )
        .then(response => {
          helpers.successMessages(this, response.data.message);
          this.loader = false;
          this.$emit("updateStatus", response.data.data);
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    }
  },
  mounted() {
    this.status = this.slotData.status;
  }
};
</script>
<style></style>
