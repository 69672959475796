<template>
  <div>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="d-flex justify-content-start">
      <button
        @click="slotActive()"
        :class="
          slotButtonActive === true
            ? 'btn-slot mr-3 btn activeSlotbtn'
            : 'btn-slot mr-3 btn '
        "
      >
        Slot
      </button>

      <button
        @click="reservationActive()"
        :class="
          reservationButtonActive === true
            ? ' btn-slot btn activeSlotbtn'
            : 'btn-slot btn '
        "
      >
        Reservation
      </button>
    </div>
    <div title="Reservation Slots " v-if="slot">
      <h4 class="card-label mb-3 mt-3 border-bottom">
        Add Slot
      </h4>
      <!--start Add Slots-wrapper-->
      <div class="pt-3 pb-3">
        <div class="addSlotsNew-wrapper">
          <p>
            Choose what times each week your restaurant is available to book
            reservations.
          </p>
          <div class="row">
            <div class="col-sm-6">
              <div class="custom-checkBoxWrapper">
                <form>
                  <p>Days of the week</p>
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="form-group mr-4">
                      <input
                        type="checkbox"
                        v-model="is_monday"
                        id="is_monday"
                      />
                      <label for="is_monday">Mo</label>
                    </div>
                    <div class="form-group mr-4">
                      <input
                        type="checkbox"
                        v-model="is_tuesday"
                        id="is_tuesday"
                      />
                      <label for="is_tuesday">Tu</label>
                    </div>
                    <div class="form-group mr-4">
                      <input
                        type="checkbox"
                        v-model="is_wednesday"
                        id="is_wednesday"
                      />
                      <label for="is_wednesday">We</label>
                    </div>
                    <div class="form-group mr-4">
                      <input
                        type="checkbox"
                        v-model="is_thursday"
                        id="is_thursday"
                      />
                      <label for="is_thursday">Th</label>
                    </div>
                    <div class="form-group mr-4">
                      <input
                        type="checkbox"
                        v-model="is_friday"
                        id="is_friday"
                      />
                      <label for="is_friday">Fr</label>
                    </div>
                    <div class="form-group mr-4">
                      <input
                        type="checkbox"
                        v-model="is_saturday"
                        id="is_saturday"
                      />
                      <label for="is_saturday">Sa</label>
                    </div>
                    <div class="form-group">
                      <input
                        type="checkbox"
                        v-model="is_sunday"
                        id="is_sunday"
                      />
                      <label for="is_sunday">Su</label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="sel12" class="text-left">Start</label>
                    <select
                      id="sel12"
                      class="form-control bg-gray"
                      v-model="startTime"
                    >
                      <option disabled>select start time</option>
                      <option v-for="time in getTimeList" :key="time"
                        >{{ time }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="sel1" class="text-left">End</label>
                    <select
                      class="form-control bg-gray"
                      v-model="endTime"
                      id="sel1"
                    >
                      <option disabled>select end time</option>
                      <option v-for="time in getTimeList" :key="time"
                        >{{ time }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-2"></div>
          </div>
        </div>
        <div class="mt-4">
          <!--          <button class="btn addNewSceduling d-block mb-3">-->
          <!--            Add new scheduling rule-->
          <!--          </button>-->
          <button
            v-if="isEdit"
            @click="updateSlots"
            class="btn addNewSceduling mr-3"
          >
            Save slots
          </button>
          <button v-else @click="addSlots" class="btn mr-3 addNewSceduling">
            Save slots
          </button>
          <button @click="resetField()" class="btn addNewSceduling">
            Reset
          </button>
        </div>
      </div>
      <!--end Add Slots-wrapper-->
      <!--start All Slots-wrapper-->
      <h4 class="card-label mb-3 border-bottom">
        All Slots
      </h4>
      <div class="demo-app reservation-slots-wrapper">
        <div class="demo-app-main">
          <div class="container reserved-slots">
            <div class="row">
              <div class="d-flex flex-wrap w-100">
                <div v-if="availableSlots.length === 0">
                  No Slot found!
                </div>
                <div
                  class="widget widget-reminder mb-3"
                  v-for="(slot, index) in availableSlots"
                  :key="index"
                >
                  <div class="widget-reserved-wrapper">
                    <div
                      :class="
                        checkSlotObject(slot)
                          ? 'bg-color-beige widget-reminder-container'
                          : 'widget-reminder-container'
                      "
                    >
                      <h2>Slots {{ ++index }}</h2>
                      <div
                        class="pt-2 justify-content-center text-center w-100"
                        style="color: #fd683e"
                      >
                        {{ getDays(slot) }}
                      </div>
                      <div
                        class="widget-reminder-time w-100 d-flex justify-content-between"
                      >
                        <span class="d-inline-block"
                          >Start Time <br />{{ slot.start_time }}</span
                        >
                        <span class="d-inline-block"
                          >End Time <br />{{ slot.end_time }}</span
                        >
                      </div>
                      <div class="icon-reservation table-actions">
                        <a class="reject-icon"
                          ><i
                            class="fas fa-trash-alt"
                            @click="deleteSlot(slot)"
                          ></i
                        ></a>
                        <a
                          :class="
                            checkSlotObject(slot)
                              ? 'success-icon bg-black'
                              : 'success-icon'
                          "
                        >
                          <i
                            class="fas fa-pencil-alt"
                            @click="editSlot(slot)"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end All Slots-wrapper-->
    </div>
    <div title="Reservation Slots" v-if="reservation">
      <h4 class="card-label mb-3 mt-3 border-bottom">
        Add Reservation
      </h4>
      <div class="addNewReservations-wrapper">
        <div class="d-flex justify-content-between">
          <div class="form-group">
            <label class="text-left">Date</label>
            <datepicker
              input-class="form-control form-control-solid"
              v-model="reservationDate"
              :format="DatePickerFormat"
              :disabledDates="disabledDates"
            >
            </datepicker>
          </div>
          <div class="form-group">
            <label for="sel1" class="text-left">Time</label>
            <select
              class="form-control form-control-solid"
              v-model="reservationTime"
              id="sel1"
            >
              <option v-for="time in getTimeList" :key="time"
                >{{ time }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="sel1" class="text-left">Party</label>
            <input
              v-model="persons"
              type="number"
              min="1"
              class="form-control form-control-solid"
              name="party-size"
            />
          </div>
        </div>
        <div class="contact-details mt-3">
          <div class="d-flex justify-content-between align-items-center">
            <div class="form-group">
              <label class="text-left">Name</label>
              <input
                v-model="name"
                type="text"
                class="form-control form-control-solid"
                name="name"
              />
            </div>
            <div class="form-group">
              <label class="text-left">Email</label>
              <input
                v-model="email"
                type="email"
                class="form-control form-control-solid"
                name="email"
              />
            </div>
            <div class="form-group">
              <label class="text-left">Phone</label>
              <input
                v-model="contact"
                type="tel"
                class="form-control form-control-solid"
                name="phone"
              />
            </div>
          </div>
        </div>

        <div class="form-group remarks-textarea-wrapper mt-3">
          <textarea
            class="form-control remarks-textarea"
            placeholder="Remarks"
            v-model="remarks"
          ></textarea>
        </div>
        <button @click="addReservation" class="btn addNewSceduling mt-4">
          Add Reservation
        </button>
      </div>
      <h4 class="card-label mb-3 border-bottom mt-4">
        All Reserved
      </h4>
      <div class="demo-app reservation-slots-wrapper">
        <div class="demo-app-main">
          <div class="container reserved-slots">
            <div class="row">
              <div class="d-flex flex-wrap w-100 reservation-checkInWrapper">
                <div class="col-sm-12">
                  <div class="form-group">
                    <h3 class="text-left">Reservation Check-In</h3>
                    <datepicker
                      input-class="form-control bg-gray"
                      v-model="checkInDate"
                      placeholder="please select date"
                      :format="DatePickerFormat"
                    >
                    </datepicker>
                  </div>
                </div>
                <div class="col-sm-12">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Arrived</th>
                        <th scope="col">Time</th>
                        <th scope="col">Party</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Status</th>
                        <th scope="col">Remarks</th>
                      </tr>
                    </thead>
                    <tbody v-if="reservations.length > 0">
                      <tr v-for="slot in reservations" :key="slot.uuid">
                        <th scope="row">
                          <ReservationStatusSelect
                            :slotData="slot"
                            @updateStatus="updateReservationStatus"
                          />
                        </th>
                        <th>{{ slot.check_in_time }}</th>
                        <th>{{ slot.party_size }}</th>
                        <th>{{ slot.user.name }}</th>
                        <th>{{ slot.user.email }}</th>
                        <th>{{ slot.user.mobile_number }}</th>
                        <th :class="checkArrivedStatus(slot)">
                          {{ slot.status }}
                        </th>
                        <th>{{ slot.remarks }}</th>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="6">
                          Reservation slots not Found
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Datepicker from "vuejs-datepicker";
import helpers from "../../helpers";
import ReservationStatusSelect from "./ReservationStatusSelect";
import axios from "axios";

export default {
  name: "ReservationSlots",
  computed: {
    isFormValid() {
      return !(
        this.name !== "" &&
        this.email !== "" &&
        this.contact !== "" &&
        this.persons !== "" &&
        this.slotUUID !== ""
      );
    },
    isSlotFormValid() {
      return !(
        this.slotStartDate !== "" &&
        this.slotEndDate !== "" &&
        this.description !== ""
      );
    },

    getWeekOfDates() {
      let dates = [];
      var i;
      for (i = 0; i < 7; i++) {
        let d = new Date(new Date().getTime() + i * 24 * 60 * 60 * 1000);
        dates.push(d.toDateString());
      }
      return dates;
    },
    getTimeList() {
      var x = 15;
      var times = [];
      var tt = 0;
      var ap = ["AM", "PM"];
      for (var i = 0; tt < 24 * 60; i++) {
        var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
        var mm = tt % 60; // getting minutes of the hour in 0-55 format
        times[i] =
          ("0" + (hh % 12)).slice(-2) +
          ":" +
          ("0" + mm).slice(-2) +
          " " +
          ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt = tt + x;
      }
      return times;
    }
  },
  components: {
    Datepicker,
    ReservationStatusSelect
  },
  data: function() {
    return {
      loader: false,
      permission: {
        is_add: 1,
        is_deleted: 1,
        is_edit: 1,
        web_access_enabled: 1
      },
      slotUUID: "",
      slotStartDate: "",
      slotEndDate: "",
      description: "",
      slot: true,
      reservation: false,
      reservationSlot: 1,
      slotButtonActive: true,
      reservationButtonActive: false,
      name: "",
      email: "",
      contact: "",
      persons: "",
      reservationDate: "",
      reservationTime: "",
      remarks: "",
      reservations: [],
      reservedSlotsList: [],
      availableSlots: [],
      startTime: "",
      endTime: "",
      is_monday: false,
      is_tuesday: false,
      is_wednesday: false,
      is_thursday: false,
      is_friday: false,
      is_saturday: false,
      is_sunday: false,
      restaurantUuid: "",
      isEdit: false,
      isSlotUuid: "",
      checkInDate: "",
      selectedSlotsEditActive: [
        {
          id: -1
        }
      ],
      status: [],
      status_options: [
        { text: "Cancelled" },
        { text: "Completed" },
        { text: "Pending" }
      ],
      DatePickerFormat: "dd-MM-yyyy",
      disabledDates: {
        to: new Date(Date.now() - 8640000)
      },
      // eslint-disable-next-line no-useless-escape
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    };
  },
  created() {
    // setInterval(() => this.getAvailableSlots(), 2000);
  },
  methods: {
    checkSlotObject(slot) {
      return this.selectedSlotsEditActive[0].id === slot.uuid;
    },
    slotActive() {
      this.slot = true;
      this.reservation = false;
      this.slotButtonActive = true;
      this.reservationButtonActive = false;
    },
    reservationActive() {
      this.slot = false;
      this.reservation = true;
      this.slotButtonActive = false;
      this.reservationButtonActive = true;
    },
    format24To12(time) {
      var hrs = 0;
      var min = 0;
      var timeHours = 0;
      var hours = 0;
      var tempAry = time.split(":");
      hrs = parseInt(tempAry[0], 10);
      min = parseInt(tempAry[1], 10);
      if (min === 0) {
        min = "00";
      }
      if (hrs >= 12) {
        hours = hrs - 12;
        if (hours === 12) {
          timeHours = "00:" + min + "AM";
        } else {
          timeHours = "0" + hours + ":" + min + "PM";
        }
      } else {
        timeHours = hrs + ":" + min + "AM";
      }
      return timeHours;
    },
    getDays(slot) {
      let days = slot;
      let day = "";
      if (days.is_monday) {
        day += "Mo, ";
      }
      if (days.is_tuesday) {
        day += "Tu, ";
      }
      if (days.is_wednesday) {
        day += "We, ";
      }
      if (days.is_thursday) {
        day += "Th, ";
      }
      if (days.is_friday) {
        day += "Fr, ";
      }
      if (days.is_saturday) {
        day += "Sa, ";
      }
      if (days.is_sunday) {
        day += "Su, ";
      }
      return day.slice(0, -2);
    },
    getTime(time) {
      var d1 = new Date(time);
      var minute = d1.getMinutes();
      // var minute = d1.getUTCMinutes();
      var hour = d1.getHours();
      // var hour = d1.getUTCHours();
      if (minute === 0) return this.format24To12(hour + ":00");
      else return this.format24To12(hour + ":" + minute);
    },
    getDate(dateTime) {
      var date = dateTime.split(" ");
      return date[0];
    },
    reservedSlot(uuid) {
      this.slotUUID = uuid;
    },
    checkTimeSplit(time) {
      let timeSplit = time.split(" ");
      if (timeSplit[1] === "PM") {
        let timeSplitPM = timeSplit[0].split(":");
        return (
          parseInt(timeSplitPM[0] + 12) +
          ":" +
          timeSplitPM[1] +
          " " +
          timeSplit[1]
        );
      } else {
        return time;
      }
    },
    getStartAndEndTime(time) {
      var now = new Date();
      return (
        now.getMonth() +
        1 +
        "/" +
        now.getDate() +
        "/" +
        now.getFullYear() +
        " " +
        this.checkTimeSplit(time)
      );
    },
    addSlots() {
      if (!this.checkValidationSlots()) {
        return;
      }
      this.loader = true;

      let obj = {
        is_monday: this.is_monday,
        is_tuesday: this.is_tuesday,
        is_wednesday: this.is_wednesday,
        is_thursday: this.is_thursday,
        is_friday: this.is_friday,
        is_saturday: this.is_saturday,
        is_sunday: this.is_sunday,
        end_time: this.endTime,
        start_time: this.startTime
      };
      ApiService.post(
        this.$path +
          "/admin/reservation/slots/restaurant/" +
          this.restaurantUuid,
        obj
      )
        .then(response => {
          helpers.successMessages(this, response.data.message);
          this.loader = false;
          this.setAvailableSlotsEmptyValue();
          this.availableSlots.unshift(response.data.data);
        })

        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    setAvailableSlotsEmptyValue() {
      this.is_monday = false;
      this.is_tuesday = false;
      this.is_wednesday = false;
      this.is_thursday = false;
      this.is_friday = false;
      this.is_saturday = false;
      this.is_sunday = false;
      this.startTime = "";
      this.endTime = "";
      this.isSlotUuid = "";
    },
    checkValidationSlots() {
      if (
        !this.is_monday &&
        !this.is_tuesday &&
        !this.is_wednesday &&
        !this.is_thursday &&
        !this.is_friday &&
        !this.is_saturday &&
        !this.is_sunday
      ) {
        helpers.errorMessage(this, "please select at least one day!");
        return false;
      }
      if (this.startTime === "") {
        helpers.errorMessage(this, "The start time is required!");
        return false;
      }
      if (this.endTime === "") {
        helpers.errorMessage(this, "The end time is required!");
        return false;
      }

      if (this.endTime === this.startTime) {
        helpers.errorMessage(
          this,
          "The start time and end time should not be same"
        );
        return false;
      }

      if (
        this.getStartAndEndTime(this.startTime) >
        this.getStartAndEndTime(this.endTime)
      ) {
        helpers.errorMessage(
          this,
          "The start time should be greater than end time!"
        );
        return false;
      }
      return true;
    },
    updateSlots() {
      if (!this.checkValidationSlots()) {
        return;
      }

      this.loader = true;

      let obj = {
        is_monday: this.is_monday,
        is_tuesday: this.is_tuesday,
        is_wednesday: this.is_wednesday,
        is_thursday: this.is_thursday,
        is_friday: this.is_friday,
        is_saturday: this.is_saturday,
        is_sunday: this.is_sunday,
        end_time: this.endTime,
        start_time: this.startTime
      };
      ApiService.patch(
        this.$path +
          "/admin/reservation/slots/restaurant/" +
          this.restaurantUuid +
          "/" +
          this.isSlotUuid,
        obj
      )
        .then(response => {
          helpers.successMessages(this, response.data.message);
          let index = this.availableSlots.findIndex(
            x => x.uuid === response.data.data.uuid
          );
          if (index !== -1) {
            this.availableSlots[index].is_monday = response.data.data.is_monday;
            this.availableSlots[index].is_tuesday =
              response.data.data.is_tuesday;
            this.availableSlots[index].is_wednesday =
              response.data.data.is_wednesday;
            this.availableSlots[index].is_thursday =
              response.data.data.is_thursday;
            this.availableSlots[index].is_friday = response.data.data.is_friday;
            this.availableSlots[index].is_saturday =
              response.data.data.is_saturday;
            this.availableSlots[index].is_sunday = response.data.data.is_sunday;
            this.availableSlots[index].endTime = response.data.data.end_time;
            this.availableSlots[index].startTime =
              response.data.data.start_time;
          }
          this.isEdit = false;
          this.selectedSlotsEditActive[0].id = -1;
          this.setAvailableSlotsEmptyValue();
          this.getAvailableSlots();
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    resetField() {
      this.isEdit = false;
      this.is_monday = false;
      this.is_tuesday = false;
      this.is_wednesday = false;
      this.is_thursday = false;
      this.is_friday = false;
      this.is_saturday = false;
      this.is_sunday = false;
      this.startTime = "";
      this.endTime = "";
      this.isSlotUuid = "";
      this.selectedSlotsEditActive[0].id = -1;
    },
    deleteSlot(slot) {
      this.loader = true;
      ApiService.delete(
        this.$path +
          "/admin/reservation/slots/restaurant/" +
          this.restaurantUuid +
          "/" +
          slot.uuid
      )
        .then(response => {
          helpers.successMessages(this, response.data.message);
          let index = this.availableSlots.findIndex(
            x => x.uuid === response.data.data.uuid
          );
          if (index !== -1) {
            this.availableSlots.splice(index, 1);
          }
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    editSlot(slot) {
      this.isEdit = true;
      this.is_monday = slot.is_monday;
      this.is_tuesday = slot.is_tuesday;
      this.is_wednesday = slot.is_wednesday;
      this.is_thursday = slot.is_thursday;
      this.is_friday = slot.is_friday;
      this.is_saturday = slot.is_saturday;
      this.is_sunday = slot.is_sunday;
      this.startTime = slot.start_time;
      this.endTime = slot.end_time;
      this.isSlotUuid = slot.uuid;
      this.selectedSlotsEditActive[0].id = slot.uuid;
    },
    getAvailableSlots() {
      this.loader = true;
      axios
        .get(
          this.$path +
            "/admin/reservation/slots/restaurant/" +
            this.restaurantUuid
        )
        .then(response => {
          this.loader = false;
          this.availableSlots = response.data.data;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    addReservation() {
      if (!this.reg.test(this.email)) {
        helpers.errorMessage(this, "Please Enter Correct Email");
        return;
      }
      this.loader = true;
      let obj = {
        name: this.name,
        email: this.email,
        mobile_number: this.contact,
        party_size: this.persons,
        check_in_time: this.reservationTime,
        check_in_date:
          this.reservationDate !== ""
            ? this.dateFormateChange(this.reservationDate)
            : "",
        remarks: this.remarks
      };
      ApiService.post(
        this.$path + "/admin/reservation/restaurant/" + this.restaurantUuid,
        obj
      )
        .then(response => {
          helpers.successMessages(this, response.data.message);
          this.name = "";
          this.email = "";
          this.contact = "";
          this.persons = "";
          this.remarks = "";
          this.reservationTime = "";
          this.checkInDate = this.reservationDate;
          this.reservationDate = "";
          this.loader = false;
          this.getReservation();
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getReservation() {
      if (this.checkInDate === "") {
        this.errorEmail = "Please select date";
        helpers.errorMessage(this, "Please select date");
        return;
      }
      this.loader = true;
      let data = {
        params: {
          date: this.dateFormateChange(this.checkInDate)
        }
      };
      this.reservations = [];
      axios
        .get(
          this.$path + "/admin/reservation/restaurant/" + this.restaurantUuid,
          data
        )
        .then(res => {
          this.reservations = res.data.data;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    updateReservationStatus(data) {
      let index = this.reservations.findIndex(x => x.uuid === data.uuid);
      if (index !== -1) {
        this.reservations[index].status = data.status;
      }
    },
    checkArrivedStatus(slot) {
      if (slot.status === "Completed") {
        return "text-green";
      }
      if (slot.status === "Cancelled") {
        return "text-danger";
      }
    },
    dateFormateChange(date) {
      let curr_date = date.getDate();
      let curr_month = date.getMonth() + 1;
      let curr_year = date.getFullYear();
      if (curr_month.toString().length === 1) {
        curr_month = +"0" + curr_month.toString();
      }
      if (curr_date.toString().length === 1) {
        curr_date = +"0" + curr_date.toString();
      }
      return curr_year + "-" + curr_month + "-" + curr_date;
    }
  },
  mounted() {
    this.restaurantData = JSON.parse(localStorage.getItem("restaurantDetails"));
    this.restaurantId = this.restaurantData.id;
    this.restaurantUuid = this.restaurantData.uuid;
    this.getAvailableSlots();
    let permission = JSON.parse(localStorage.getItem("currentUser")).permission;
    this.permission = {
      is_add: permission.is_add,
      is_deleted: permission.is_deleted,
      is_edit: permission.is_edit,
      web_access_enabled: permission.web_access_enabled
    };
  },
  watch: {
    checkInDate(val) {
      if (!val) return;
      this.getReservation();
    },
    slotStartDate() {
      var timeStr = this.slotStartDate;
      if (
        timeStr &&
        timeStr.indexOf(" ") !== -1 &&
        timeStr.indexOf(":") !== -1
      ) {
        var hrs = 0;
        var tempAry = timeStr.split(" ");
        var date = tempAry[0];
        var hrsMinAry = tempAry[1].split(":");
        hrs = parseInt(hrsMinAry[0], 10);
        if ((tempAry[2] == "AM" || tempAry[2] == "am") && hrs == 12) {
          hrs = 0;
        } else if ((tempAry[2] == "PM" || tempAry[2] == "pm") && hrs != 12) {
          hrs += 12;
        }
        var timeFormat =
          date +
          " " +
          ("0" + hrs).slice(-2) +
          ":" +
          ("0" + parseInt(hrsMinAry[1], 10)).slice(-2) +
          ":00";
        this.slotStartDate = timeFormat;
        // console.log('Selected Time date : ', timeFormat);
        // return ('0' + hrs).slice(-2) + ':' + ('0' + parseInt(hrsMinAry[1], 10)).slice(-2);
      }
    },
    slotEndDate() {
      var timeStr = this.slotEndDate;
      if (
        timeStr &&
        timeStr.indexOf(" ") !== -1 &&
        timeStr.indexOf(":") !== -1
      ) {
        var hrs = 0;
        var tempAry = timeStr.split(" ");
        var date = tempAry[0];
        var hrsMinAry = tempAry[1].split(":");
        hrs = parseInt(hrsMinAry[0], 10);
        if ((tempAry[2] == "AM" || tempAry[2] == "am") && hrs == 12) {
          hrs = 0;
        } else if ((tempAry[2] == "PM" || tempAry[2] == "pm") && hrs != 12) {
          hrs += 12;
        }
        var timeFormat =
          date +
          " " +
          ("0" + hrs).slice(-2) +
          ":" +
          ("0" + parseInt(hrsMinAry[1], 10)).slice(-2) +
          ":00";
        this.slotEndDate = timeFormat;
      }
    }
  }
};
</script>

<style scoped lang="css">
h2 {
  margin: 0;
  font-size: 18px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.demo-app {
  display: flex;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  flex-grow: 1;
  padding: 0rem;
}

.fc {
  /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

.widget {
  background: #fff;
  margin-bottom: 0.75rem;
  display: block;
  position: relative;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
}

.widget .widget-header,
.widget-reminder-content,
.widget-reminder-time,
a.widget-header-title {
  padding: 0.625rem;
}

.widget-reminder-content .add-btn,
.addSlots-wrapper .add-btn {
  background-color: #fd683e;
  padding: 3px 10px;
  color: #fff3f0;
  border-radius: 5px;
}

.text-green {
  color: green;
}

.icon-reservation {
  position: absolute;
  top: 5px;
  right: 10px;
}

.widget-chat-message:after,
.widget-chat-message:before,
.widget-reminder-container:after,
.widget-reminder-container:before {
  display: table;
  content: "";
  clear: both;
}

.widget-footer.with-border,
.widget-reminder-container + .widget-reminder-container {
  border-top: 1px solid #efeff4;
}

.widget-footer.with-bg,
.widget-header.with-bg {
  background: #efeff4;
}

.widget-header.with-border {
  border-bottom: 1px solid #efeff4;
}

.widget-reminder-header {
  background: #efeff4;
  padding: 0.625rem;
  font-size: 14px;
  font-weight: 700;
  color: #8a8a8f;
}

.widget-reminder-container {
  position: relative;
  padding: 5px;
}

.inverse-mode .widget-reminder-container + .widget-reminder-container {
  border-color: #333;
}

.inverse-mode .widget-reminder-header {
  background: #212121;
}

.widget-reminder-time {
  width: 160px;
  float: left;
  text-align: right;
  font-size: 14px;
}

.widget-reminder-divider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 165px;
  width: 0.125rem;
  background: #fd683e;
}

.widget-reminder-content {
  margin-left: 160px;
}

.widget-header-title {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 500;
}

.widget .widget-title,
.widget .widget-title a {
  font-size: 0.75rem;
  color: #000;
  line-height: 1rem;
}

.widget .widget-title {
  font-weight: 600;
  margin: 0;
}

.widget-desc,
.widget-desc a {
  font-size: 0.6875rem;
  line-height: 1rem;
  color: #8a8a8f;
  font-weight: 500;
}

.img-circle {
  border-radius: 50%;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.widget .widget-header,
.widget-reminder-content,
.widget-reminder-time,
a.widget-header-title {
  padding: 0.625rem;
}

.reserved-slots .widget-reminder {
  flex: 0 0 calc(25% - 10px);
  max-width: calc(25% - 10px);
  margin: 0 5px;
  border-radius: 5px;
}

.reserved-slots .widget-reminder-time {
  width: 70px;
  text-align: left;
}

.reserved-slots .widget-reminder-divider {
  left: 75px;
}

.reserved-slots .widget-reminder-content {
  margin-left: 70px;
}

.reserved-slots .widget-title {
  font-size: 12px;
}

.reserved-slots .widget-desc {
  margin-top: 5px;
  font-size: 12px;
}

.widget-reserved-wrapper {
  max-height: 160px;
  height: 100%;
  overflow-y: auto;
}

.widget-reserved-wrapper .widget-reminder-time {
  padding: 12px 0 0 !important;
}

/**/
.availableSlotsTimer {
  max-height: 175px;
  overflow-y: auto;
}

.InputGroup.availableSlots {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.availableSlots input[type="radio"] {
  visibility: hidden; /* 1 */
  height: 0; /* 2 */
  width: 0; /* 2 */
}

.availableSlots label {
  display: flex;
  flex: auto;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  background-color: grey;
  color: white;
  padding: 5px 10px;
  border-radius: 6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 45px;
  line-height: normal;
}

.availableSlots label:last-of-type {
  margin-right: 0;
}

.availableSlots input[type="radio"]:checked + label {
  background-color: #fd683e;
  color: #fff;
}

.availableSlots .availableSlots-inner {
  width: calc(50% - 5px);
  flex: 0 0 calc(50% - 5px);
  margin-right: 5px;
  height: 45px;
  line-height: 0px;
  margin-bottom: 10px;
}

.availableSlots .availableSlots-inner label {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0;
}

.availableSlots .availableSlots-inner label span {
  display: inline-block;
}

.availableSlots .availableSlots-inner label span:last-child {
  margin-top: 5px;
}

.activeReserveSlot {
  background-color: #fd683e !important;
}

/*checkbox*/
.bg-gray {
  background-color: #ddd;
}

.addSlotsNew-wrapper {
  border-bottom: 1px solid #ccc;
  position: relative;
}

.addSlotsNew-wrapper .fa-times-circle {
  position: absolute;
  top: 0;
  right: 0;
  color: #fd683e;
  font-size: 18px;
  cursor: pointer;
}

.addSlotsNew-wrapper .weekely-text {
  position: relative;
}

.addSlotsNew-wrapper .weekely-text:after {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 50px;
  height: 1px;
  background-color: #ccc;
  left: 0;
}

.addNewSceduling {
  border: 1px solid #fd683e;
}

.addNewSceduling:hover {
  background-color: #fd683e;
  color: #fff;
}

.addSlotsNew-wrapper .allDays-text {
  position: relative;
}

.addSlotsNew-wrapper .allDays-text:after {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 100px;
  height: 1px;
  background-color: #ccc;
  left: 0;
}

.custom-checkBoxWrapper .new {
  padding: 50px;
}

.custom-checkBoxWrapper .form-group {
  display: block;
  margin-bottom: 15px;
}

.custom-checkBoxWrapper .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.custom-checkBoxWrapper .form-group label {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 25px;
  text-align: center;
}

.custom-checkBoxWrapper .form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #fd683e;
  -webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
    inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
    inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
}

.custom-checkBoxWrapper .form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #fd683e;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/*.reservationSlots-wholeWrapper {*/
/*  max-width: 80%;*/
/*  margin: 0 auto;*/
/*}*/

.addNewReservations-wrapper .form-group {
  flex: 0 0 calc(33.33% - 10px);
  max-width: calc(33.33% - 10px);
}

.remarks-textarea-wrapper {
  width: 100%;
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.form-control.remarks-textarea {
  width: 100%;
  min-height: 70px !important;
  height: auto !important;
  padding-top: 10px !important;
}
.activeSlotbtn {
  background-color: #fd683e !important;
  border-color: #fd683e !important;
  color: #fff !important;
}
.btn-slot {
  border: 1px solid #e9ecef;
  height: 40px;
  font-size: 14px;
  width: 115px;
  color: #9b9b9b;
  border-radius: 5px;
}
.btn-slot:hover {
  color: #000;
}
.bg-color-beige {
  border: 1px solid #fd683e;
}
/**/

@media only screen and (max-width: 1200px) {
  .widget-reminder-time {
    float: none;
    text-align: center;
    width: 100%;
  }

  .widget-reminder-divider {
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;
    width: calc(100% - 30px);
    background: transparent;
    border-top: 1px solid red;
    top: 45px;
  }

  .widget-reminder-content {
    margin-left: 0;
  }
}

.bg-black {
  background-color: black;
}

@media only screen and (max-width: 767px) {
  .p-mobile-0 {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
